import { Field, useForm, useFormState } from 'react-final-form';
import { Chip, Chips, FormField, Skeleton } from '@indriver/nova';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { sendAnalyticsEvent } from 'shared/lib/send-analytics-event';
import { toBeRequired } from 'common/lib';
import { ArrowLeftRight, Close } from '@indriver/mireska';
import { variables } from '@indriver/yrel';
import { useTranslation } from 'next-i18next';
import useWindowSize from 'shared/hooks/useWindowSize';
import { IAutofilledAddress, IRecPrice, IRecPriceRequest, ISearchItemAutocomplete } from 'common/api';
import addMinutes from 'date-fns/addMinutes';
import { roundToNearest15Minutes } from 'shared/lib/round-to-nearest-15-minutes';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import RoundClose from 'shared/ui/round-close';
import useIsRTL from 'shared/hooks/useIsRTL';
import useOnClickOutside from 'shared/hooks/useOnClickOutside';
import { IMinMaxPrices, IOrderValues } from 'widgets/address-order-form/address-order-form';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'context/location';
import { getUserAddressByCoords } from 'common/api/order';
import HintRecommendedPrice from 'features/hint-recommended-price';
import RideWithTollsModal from 'features/ride-with-tolls/ride-with-tolls-modal';
import { ABTogglesContext } from 'context/ab-toggles';
import { Experiments } from 'common/api/ab';
import { ISearchItem } from 'shared/ui/input-search/input-search';
import { InputPlaceholderBudge } from 'widgets/address-order-form/ui/input-search/input-search.styles';
import { AddressAColor, AddressBColor } from '@indriver/mireska/colored';
import * as Sentry from '@sentry/nextjs';
import Button from 'shared/ui/button/button';
import { OrderInputNumber } from './order-input-number';
import { InputCounter } from './input-counter';
import { MobileInputCounter } from './mobile-input-counter';
import { OrderInput } from './order-input';
import { HintRadioButton } from './hint-radio-button';
import {
    InputWrapper,
    MobileDataPickerWrapper,
    ChipItemStyle,
    ChipsStyle,
    StyledButton,
    StyledOrderWrapper,
    ChangeButton,
} from './order-form.style';

const DatePickerMobile = dynamic(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async () =>
        import('@indriver/nova').then((module) => module.DatePickerMobile).catch((e) => Sentry.captureException(e)),
    {
        loading: () => <Skeleton height='56px' />,
        ssr: false,
    },
);
const InputDatepicker = dynamic(async () => import('shared/ui/input-datepicker'), {
    loading: () => <Skeleton height='56px' />,
    ssr: false,
});
const MobileInputSearch = dynamic(async () => import('./mobile-input-search'), {
    loading: () => <Skeleton height='56px' />,
    ssr: false,
});

const InputSearch = dynamic(async () => import('./input-search'), {
    loading: () => <Skeleton height='56px' />,
    ssr: false,
});

interface IProps {
    autofilledCities: IAutofilledAddress | null;
    minMaxPrice: Record<string, IMinMaxPrices> | null;
    getMinMaxPrice: (city_id: string | number, city_to_id?: string | number | null) => Promise<void>;
    getRecPrice: IRecPriceRequest;
    getAddressAutocompleteFrom: (search: string) => Promise<ISearchItemAutocomplete[]>;
    getAddressAutocompleteTo: (search: string) => Promise<ISearchItemAutocomplete[]>;
    getCityAutocomplete: (search: string) => Promise<ISearchItem[]>;
    handleSubmit: () => void;
    isLoading: boolean;
    autofilledPrice?: number;
}

const { publicRuntimeConfig = {} } = getConfig();

export const WrappedForm: FC<IProps> = ({
    handleSubmit,
    autofilledCities,
    minMaxPrice,
    getMinMaxPrice,
    getRecPrice,
    isLoading,
    autofilledPrice,
    getAddressAutocompleteFrom,
    getAddressAutocompleteTo,
}) => {
    const form = useForm();
    const { values } = useFormState<IOrderValues>();
    const [visibleCounter, setVisibleCounter] = useState(false);
    const { width } = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const { t, i18n } = useTranslation();
    const { direction } = useIsRTL();
    const [recPrice, setRecPrice] = useState<IRecPrice | null>(null);
    const { toggles } = useContext(ABTogglesContext);
    const tollsExperiment = toggles?.experiments?.[Experiments.INTERCITY3_WEB_TOLLS];

    useOnClickOutside(ref, () => width >= 521 && setVisibleCounter(false), 'mouseup');

    const [location] = useLocation();

    const { data: userAddress, refetch } = useQuery({
        queryKey: ['getUserLocation'],
        queryFn: async () =>
            !!(location.latitude && location.longitude) &&
            getUserAddressByCoords(location?.latitude, location?.longitude),
        initialData: null,
        enabled: !!(location.latitude && location.longitude),
        // staleTime: 60000, // время, в течение которого данные считаются актуальными (в миллисекундах)
        // cacheTime: 3600000,
    });

    const refetchUserLocation = async () => {
        return refetch();
    };

    useEffect(() => {
        if (autofilledCities?.fromCity?.value && autofilledCities.toCity?.value) {
            form.change('address_from', autofilledCities.fromCity);
            form.change('address_to', autofilledCities.toCity);
            form.change('currency_code', autofilledCities.fromCity?.data?.city?.currency_code);
            form.change('timezone', autofilledCities.fromCity?.data?.city?.timezone);

            const dateTimeField = form.getFieldState('departure_time')?.value;
            const minimalDatetime = utcToZonedTime(
                addMinutes(roundToNearest15Minutes(new Date()), 15),
                autofilledCities.fromCity?.data?.city?.timezone,
            );

            const currentDate = roundToNearest15Minutes(dateTimeField);
            const selectedDate = new Date(minimalDatetime);

            if (currentDate.getTime() < selectedDate.getTime()) {
                form.change('departure_time', minimalDatetime);
            }
            // getMinMaxPrice(autofilledCities.fromCity?.value, autofilledCities.toCity?.value)
            //     .then((r) => r)
            //     .catch((e) => e);
        }
    }, [autofilledCities?.fromCity?.value, autofilledCities?.toCity?.value]);

    useEffect(() => {
        if (autofilledPrice) {
            form.change('price', autofilledPrice);
        }
    }, [autofilledPrice]);

    const cityFrom = form.getFieldState('address_from')?.value as ISearchItemAutocomplete;
    const cityTo = form.getFieldState('address_to')?.value as ISearchItemAutocomplete;
    const radioType = form.getFieldState('type')?.value as 'pool' | 'private';

    const handleUpdateRecPrice = async () => {
        const isAvailableCountry = publicRuntimeConfig.REC_PRICE_AVAILABLES_COUNTRIES.some(
            (countryId: number) => countryId === cityFrom?.data?.city?.country_id,
        );

        if (!isAvailableCountry) {
            setRecPrice(null);
            form.change('price', '');
            return;
        }
        if (!(radioType === 'private' && values.passengers_count > 1 && !!recPrice?.private_price)) {
            if (cityFrom?.value && cityTo?.value && values.passengers_count) {
                const res = await getRecPrice(cityFrom?.data.city.id, cityTo?.data.city.id, values.passengers_count);
                const prices = res as IRecPrice;
                if (radioType === 'pool') {
                    if (prices?.pool_price !== 0) {
                        form.change('price', prices.pool_price);
                        sendAnalyticsEvent('web.intercity.pass.rec_price_autoinsert', {
                            // city_id: getItemFromStorage('X-City-Id'),
                            from_city_id: cityFrom?.data.city.id,
                            to_city_id: cityTo?.data.city.id,
                            rec_price: prices.pool_price,
                            order_type: 'pool',
                            passengers_count: values.passengers_count,
                        });
                    }
                } else if (prices?.private_price !== 0) {
                    form.change('price', prices.private_price);
                    sendAnalyticsEvent('web.intercity.pass.rec_price_autoinsert', {
                        // city_id: getItemFromStorage('X-City-Id'),
                        from_city_id: cityFrom?.data.city.id,
                        to_city_id: cityTo?.data.city.id,
                        rec_price: prices.private_price,
                        order_type: 'private',
                        passengers_count: values.passengers_count,
                    });
                }
                setRecPrice(prices);
            }
        }
    };
    useEffect(() => {
        handleUpdateRecPrice()
            .then((r) => r)
            .catch((e) => Sentry.captureException(e));
    }, [cityFrom?.value, radioType, cityTo?.value, values.passengers_count]);

    const recPriceValue = useMemo(() => recPrice?.[`${radioType}_price`], [radioType, recPrice]);

    useEffect(() => {
        // const recPriceValue = recPrice?.[`${radioType}_price`];
        if (recPriceValue) {
            form.change('price', recPriceValue);
        }
        if (recPriceValue === 0) {
            form.change('price', '');
        }
    }, [radioType, recPrice]);

    const [openedModalTolls, setOpenedModalTolls] = useState(false);

    // useEffect(() => {
    //     // if (cityFrom?.data && cityTo?.data && cityFrom?.data?.city?.country_id === cityTo?.data?.city?.country_id) {
    //     if (!!cityFrom?.data && !!cityTo?.data && tollsExperiment?.before_click_find) {
    //         setOpenedModalTolls(true);
    //     }
    // }, [cityFrom?.value, cityTo?.value]);

    return (
        <form onSubmit={handleSubmit}>
            <Field name='type'>
                {(props) => {
                    return (
                        <FormField dir={direction} invalid={false} role='group' style={{ flexDirection: 'row' }}>
                            <Chips
                                dir={direction}
                                multiple={false}
                                active={[props.input.value]}
                                size={width <= 520 ? 'm' : 'l'}
                                inline
                                className={ChipsStyle}
                                onChange={(id) => {
                                    if (id) {
                                        const [value] = id;
                                        props.input.onChange(value);
                                        sendAnalyticsEvent('web.intercity.passenger.full_car.click', {
                                            event_value: value === 'private',
                                        });
                                    }
                                }}>
                                <Chip
                                    id='private'
                                    className={ChipItemStyle}
                                    role='button'
                                    postfix={<HintRadioButton />}
                                    active={props.input.value === 'private'}>
                                    {t('order.without_fellow_travelers')}
                                </Chip>
                                <Chip
                                    id='pool'
                                    className={ChipItemStyle}
                                    role='button'
                                    active={props.input.value === 'pool'}>
                                    {t('order.with_fellow_travelers')}
                                </Chip>
                            </Chips>
                        </FormField>
                    );
                }}
            </Field>
            <StyledOrderWrapper>
                <Field
                    name='address_from'
                    validate={(value: ISearchItemAutocomplete) =>
                        toBeRequired(value?.value || value?.data?.city?.id, t('order.input_city'))
                    }>
                    {(props) => {
                        return (
                            <FormField
                                dir={direction}
                                className={InputWrapper}
                                hint={props.meta.error && props.meta.touched && props.meta.error}
                                invalid={props.meta.touched ? !!props.meta.error : false}
                                onFocus={() => sendAnalyticsEvent('web.intercity.passenger.from.click')}>
                                <>
                                    {width <= 520 ? (
                                        <MobileInputSearch
                                            isFrom
                                            dir={direction}
                                            selectedItem={props.input.value as unknown as ISearchItemAutocomplete}
                                            placeholder={t('order.address')}
                                            placeholderComponent={({ clearField }) => (
                                                <InputPlaceholderBudge>
                                                    <AddressAColor height={12} width={12} />
                                                    {values.address_from?.data.city.name ?? t('order.from_city')}
                                                    {values?.address_from?.data?.city?.name && (
                                                        <span onClickCapture={() => clearField()}>
                                                            <Close size={12} />
                                                        </span>
                                                    )}
                                                </InputPlaceholderBudge>
                                            )}
                                            loadData={getAddressAutocompleteFrom}
                                            getUserLocation={refetchUserLocation}
                                            userAddress={userAddress}
                                            changeBtn={
                                                <ChangeButton
                                                    tabIndex={-1}
                                                    role='button'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        props.input.value && cityTo && form.mutators.changeCities();
                                                        e.stopPropagation();
                                                    }}>
                                                    <ArrowLeftRight
                                                        size={20}
                                                        color={
                                                            props.input.value && cityTo
                                                                ? variables['text-and-icon-primary']
                                                                : variables['text-and-icon-disabled']
                                                        }
                                                    />
                                                </ChangeButton>
                                            }
                                            onSelect={async (item) => {
                                                if (item) {
                                                    form.change('timezone', item.data.city.timezone);
                                                    props.input.onChange(item);
                                                    sendAnalyticsEvent('web.intercity.passenger.from_done', {
                                                        event_value: item.label,
                                                    });
                                                    if (item?.data.city.currency_code) {
                                                        form.change('currency_code', item.data.city.currency_code);
                                                    }
                                                    await getMinMaxPrice(item?.data?.city?.id, cityTo?.data?.city?.id);
                                                } else {
                                                    form.change('currency_code', '');
                                                    props.input.onChange(null);
                                                }
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <InputSearch
                                                isFrom
                                                dir={direction}
                                                invalid={props.meta.touched ? !!props.meta.error : false}
                                                selectedItem={props.input.value as unknown as ISearchItemAutocomplete}
                                                placeholder={t('order.address')}
                                                placeholderComponent={({ clearField }) => (
                                                    <InputPlaceholderBudge>
                                                        <AddressAColor height={12} width={12} />
                                                        {values.address_from?.data.city.name ?? t('order.from_city')}
                                                        {values?.address_from?.data?.city?.name && (
                                                            <span onClickCapture={() => clearField()}>
                                                                <Close size={12} />
                                                            </span>
                                                        )}
                                                    </InputPlaceholderBudge>
                                                )}
                                                loadData={getAddressAutocompleteFrom}
                                                getUserLocation={refetchUserLocation}
                                                userAddress={userAddress}
                                                changeBtn={
                                                    <ChangeButton
                                                        tabIndex={-1}
                                                        role='button'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            props.input.value && cityTo && form.mutators.changeCities();
                                                            e.stopPropagation();
                                                        }}>
                                                        <ArrowLeftRight
                                                            size={20}
                                                            color={
                                                                props.input.value && cityTo
                                                                    ? variables['text-and-icon-primary']
                                                                    : variables['text-and-icon-disabled']
                                                            }
                                                        />
                                                    </ChangeButton>
                                                }
                                                onSelect={async (item) => {
                                                    if (item) {
                                                        const dateTimeField =
                                                            form.getFieldState('departure_time')?.value;
                                                        const minimalDatetime = utcToZonedTime(
                                                            addMinutes(roundToNearest15Minutes(new Date()), 15),
                                                            item.data.city.timezone,
                                                        );

                                                        const currentDate = roundToNearest15Minutes(dateTimeField);
                                                        const selectedDate = new Date(minimalDatetime);

                                                        if (currentDate.getTime() < selectedDate.getTime()) {
                                                            form.change('departure_time', minimalDatetime);
                                                        }
                                                        props.input.onChange(item);
                                                        form.change('timezone', item?.data?.city?.timezone);

                                                        sendAnalyticsEvent('web.intercity.passenger.from_done', {
                                                            event_value: item.label,
                                                        });
                                                        if (item?.data?.city?.currency_code) {
                                                            form.change(
                                                                'currency_code',
                                                                item?.data?.city?.currency_code,
                                                            );
                                                        }
                                                        await getMinMaxPrice(
                                                            item?.data?.city?.id,
                                                            cityTo?.data?.city?.id,
                                                        );
                                                    } else {
                                                        form.change('currency_code', '');
                                                        props.input.onChange(null);
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                    {/* <LocationInputHint /> */}
                                </>
                            </FormField>
                        );
                    }}
                </Field>
                <Field<ISearchItemAutocomplete | null>
                    name='address_to'
                    validate={(value, formValues) => {
                        const state = formValues as IOrderValues;
                        if (
                            state?.address_from?.value?.address &&
                            state?.address_to?.value?.address &&
                            state?.address_from?.value?.address === state?.address_to?.value?.address
                        ) {
                            return t('order.select_different_addresses');
                        }
                        if (
                            state?.address_from &&
                            state?.address_to &&
                            state?.address_from?.data?.city?.id === state?.address_to?.data?.city?.id
                        ) {
                            return t('order.select_different_cities');
                        }

                        return toBeRequired(value?.value || value?.data?.city?.id, t('order.input_city'));
                    }}>
                    {(props) => {
                        return (
                            <FormField
                                dir={direction}
                                className={InputWrapper}
                                invalid={props.meta.touched ? !!props.meta.error : false}
                                hint={props.meta.error && props.meta.touched && props.meta.error}
                                onFocus={() => sendAnalyticsEvent('web.intercity.passenger.to.click')}>
                                {width <= 520 ? (
                                    <MobileInputSearch
                                        dir={direction}
                                        selectedItem={props.input.value as unknown as ISearchItemAutocomplete}
                                        placeholder={t('order.address')}
                                        placeholderComponent={({ clearField }) => (
                                            <InputPlaceholderBudge>
                                                <AddressBColor height={12} width={12} />
                                                {values?.address_to?.data?.city?.name ?? t('order.to_city')}
                                                {values?.address_to?.data?.city?.name && (
                                                    <span onClickCapture={() => clearField()}>
                                                        <Close size={12} />
                                                    </span>
                                                )}
                                            </InputPlaceholderBudge>
                                        )}
                                        loadData={getAddressAutocompleteTo}
                                        getUserLocation={refetchUserLocation}
                                        userAddress={userAddress}
                                        onSelect={async (item) => {
                                            if (item) {
                                                props.input.onChange(item);
                                                sendAnalyticsEvent('web.intercity.passenger.to_done', {
                                                    event_value: item.label,
                                                });
                                                await getMinMaxPrice(cityFrom?.data?.city.id, item?.data?.city?.id);
                                            } else {
                                                props.input.onChange(null);
                                            }
                                        }}
                                    />
                                ) : (
                                    <InputSearch
                                        dir={direction}
                                        selectedItem={props.input.value as unknown as ISearchItemAutocomplete}
                                        placeholder={t('order.address')}
                                        placeholderComponent={({ clearField }) => (
                                            <InputPlaceholderBudge>
                                                <AddressBColor height={12} width={12} />
                                                {values?.address_to?.data?.city?.name ?? t('order.to_city')}
                                                {values?.address_to?.data?.city?.name && (
                                                    <span onClickCapture={() => clearField()}>
                                                        <Close size={12} />
                                                    </span>
                                                )}
                                            </InputPlaceholderBudge>
                                        )}
                                        loadData={getAddressAutocompleteTo}
                                        getUserLocation={refetchUserLocation}
                                        userAddress={userAddress}
                                        onSelect={async (item) => {
                                            if (item) {
                                                props.input.onChange(item);
                                                sendAnalyticsEvent('web.intercity.passenger.to_done', {
                                                    event_value: item.label,
                                                });
                                                await getMinMaxPrice(cityFrom?.data?.city?.id, item?.data?.city?.id);
                                            } else {
                                                props.input.onChange(null);
                                            }
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>
                <Field name='departure_time'>
                    {(props) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
                            <FormField id='#date_wrapper' dir={direction}>
                                {width <= 520 ? (
                                    <MobileDataPickerWrapper
                                        dir={direction}
                                        id='mobileDatePicker'
                                        onClick={(e) => {
                                            const target = e.target as HTMLDivElement;
                                            if (
                                                target.parentElement !== null &&
                                                target.parentElement.id === 'mobileDatePicker'
                                            ) {
                                                sendAnalyticsEvent('web.intercity.passenger.dep_date.click');
                                            }
                                        }}>
                                        <DatePickerMobile
                                            value={props.input.value}
                                            selectedDateFormat={
                                                i18n.language.includes('en')
                                                    ? 'eee, dd MMMM, HH:mm'
                                                    : 'eeeeee, dd MMMM, HH:mm'
                                            }
                                            mode={[
                                                {
                                                    type: 'day',
                                                    relativeFormat: { other: 'EEEE, dd MMMM' },
                                                },
                                                { type: 'time', timeFormat: '24h' },
                                            ]}
                                            name='date'
                                            CustomCell={({ onClick, ...dateProps }) => (
                                                <span>
                                                    <OrderInput
                                                        {...dateProps}
                                                        isInputButton
                                                        value={dateProps.value as string}
                                                        dir={direction}
                                                        onClickCell={onClick}
                                                    />
                                                </span>
                                            )}
                                            title={t('order.when')}
                                            locale={i18n.language}
                                            rootSelector='#mobile-city-root'
                                            position='fixed'
                                            onChange={(value) => {
                                                props.input.onChange(value);
                                                sendAnalyticsEvent('web.intercity.passenger.dep_date_done', {
                                                    event_value: value,
                                                });
                                            }}
                                        />
                                    </MobileDataPickerWrapper>
                                ) : (
                                    <InputDatepicker
                                        dir={direction}
                                        date={props.input.value}
                                        timeZone={cityFrom?.data?.city?.timezone}
                                        placeholder={t('order.when')}
                                        onInputClick={() =>
                                            sendAnalyticsEvent('web.intercity.passenger.dep_date.click')
                                        }
                                        onChange={(value) => {
                                            props.input.onChange(value);
                                            sendAnalyticsEvent('web.intercity.passenger.dep_date_done', {
                                                event_value: value,
                                            });
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>
                <Field name='passengers_count'>
                    {(props) => {
                        return (
                            // <div ref={ref} style={{ position: 'relative' }}>
                            <OrderInput
                                dir={direction}
                                isInputButton
                                value={String(props.input.value)}
                                placeholder={
                                    radioType === 'private' ? t('order.passengers') : t('order.number_of_seats')
                                }
                                onClickCell={() => setVisibleCounter(!visibleCounter)}>
                                {width <= 520 ? (
                                    <MobileInputCounter
                                        dir={direction}
                                        inputRef={ref}
                                        visibleCounter={visibleCounter}
                                        setVisibleCounter={setVisibleCounter}
                                        setPassengersCounter={(value) => {
                                            form.change('passengers_count', value);
                                            sendAnalyticsEvent('web.intercity.passenger.pass_count_done', {
                                                event_value: value,
                                            });
                                        }}
                                        value={props.input.value}
                                        radioType={radioType}
                                    />
                                ) : (
                                    <InputCounter
                                        dir={direction}
                                        divRef={ref}
                                        visible={visibleCounter}
                                        value={props.input.value}
                                        onClickPlus={(newValue) => {
                                            form.change('passengers_count', newValue);
                                            sendAnalyticsEvent('web.intercity.passenger.pass_count_done', {
                                                event_value: newValue,
                                            });
                                        }}
                                        onClickMinus={(newValue) => {
                                            form.change('passengers_count', newValue);
                                            sendAnalyticsEvent('web.intercity.passenger.pass_count_done', {
                                                event_value: newValue,
                                            });
                                        }}
                                    />
                                )}
                            </OrderInput>
                            // </div>
                        );
                    }}
                </Field>
                <Field
                    name='price'
                    validate={(value, formValues) => {
                        const state = formValues as IOrderValues;
                        const number = Number(value);
                        if (minMaxPrice?.[state.type]) {
                            if (value === '' || value === undefined) {
                                return undefined;
                            }
                            if (number < minMaxPrice[state.type]?.min_price) {
                                return t('order.invalid_min_price', {
                                    minPrice: minMaxPrice[state.type]?.min_price,
                                    currency: state.currency_code && `${state.currency_code}`,
                                });
                            }
                            if (number > minMaxPrice[state.type]?.max_price) {
                                return t('order.invalid_max_price', {
                                    maxPrice: minMaxPrice[state.type]?.max_price,
                                    currency: state.currency_code && `${state.currency_code}`,
                                });
                            }
                        }

                        return undefined;
                    }}>
                    {(props) => {
                        // const recPriceValue = recPrice?.[`${radioType}_price`];
                        const isRecPrice = Number(props.input.value) === recPriceValue;
                        return (
                            <FormField
                                invalid={props.meta.touched ? !!props.meta.error : false}
                                hint={props.meta.error && props.meta.touched ? props.meta.error : null}
                                onFocus={() => sendAnalyticsEvent('web.intercity.passenger.price.click')}
                                onBlur={() => {
                                    sendAnalyticsEvent('web.intercity.passenger.price_done', {
                                        event_value: Number(props.input.value),
                                    });
                                }}>
                                <OrderInputNumber
                                    dir={direction}
                                    name='price'
                                    autoComplete='one-time-code'
                                    inputMode='numeric'
                                    suffix={values.currency_code}
                                    value={props.input.value}
                                    placeholder={t(
                                        isRecPrice && !!recPriceValue
                                            ? 'order.recommended_price'
                                            : 'order.offer_your_fare_optional',
                                    )}
                                    postfix={
                                        props.input.value.length > 0 && (
                                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                tabIndex={-1}
                                                role='button'
                                                onClick={() => {
                                                    form.change('price', undefined);
                                                }}>
                                                <RoundClose size={20} color={variables['text-and-icon-secondary']} />
                                            </div>
                                        )
                                    }
                                    onChange={(event, value) => {
                                        const val = value.value?.replace(/\D/g, '') ?? '';
                                        props.input.onChange(val);
                                    }}
                                />
                                {!(props.meta.error && props.meta.touched) && recPriceValue && (
                                    <HintRecommendedPrice
                                        isRecPrice={isRecPrice}
                                        recPriceValue={recPriceValue}
                                        currency={values.currency_code}
                                        onSetRecPrice={() => {
                                            form.change('price', recPriceValue);
                                            sendAnalyticsEvent('web.intercity.psg.rec_price_click', {
                                                from: cityFrom?.data.address.address,
                                                to: cityTo?.data.address.address,
                                                rec_price: recPriceValue,
                                                order_type: radioType,
                                                passengers_count: values.passengers_count,
                                            });
                                        }}
                                    />
                                )}
                            </FormField>
                        );
                    }}
                </Field>
                <Field name='currency_code'>
                    {(props) => {
                        return <input type='hidden' value={props.input.value} />;
                    }}
                </Field>
                <Field name='timezone'>
                    {(props) => {
                        return <input type='hidden' value={props.input.value} />;
                    }}
                </Field>
                <Button
                    dir={direction}
                    type='submit'
                    size='l'
                    text='order.find_driver'
                    className={StyledButton}
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={async (e) => {
                        const validate = {
                            allFieldsFilled: true,
                            cityFrom: false,
                            cityTo: false,
                            price: false,
                        };

                        validate.price = !values.price;
                        if (
                            !(values.address_from?.value || values.address_from?.data?.city?.id) ||
                            !(values.address_to?.value || values.address_to?.data?.city?.id)
                        ) {
                            validate.allFieldsFilled = false;
                            validate.cityFrom = !(values.address_from && values.address_from?.data?.city?.id);
                            validate.cityTo = !(values.address_to && values.address_to?.data?.city?.id);
                        }

                        sendAnalyticsEvent('web.intercity.passenger.find.click', {
                            is_required_fields_filled: validate.allFieldsFilled,
                            is_missed_from: validate.cityFrom,
                            is_missed_to: validate.cityTo,
                            is_missed_date: false,
                            is_missed_price: validate.price,
                            is_missed_pass_num: false,
                        });
                        if (tollsExperiment?.afterclickfind) {
                            e.preventDefault();
                            setOpenedModalTolls(true);
                        }
                    }}
                />
            </StyledOrderWrapper>
            <RideWithTollsModal
                title={recPriceValue ? 'tolls.price_doesnt_include_tolls' : 'tolls.prices_dont_include_tolls'}
                text={recPriceValue ? 'tolls.you_can_change_suggested' : 'tolls.ask_drivers_about_cost'}
                opened={openedModalTolls}
                onContinue={tollsExperiment?.afterclickfind ? handleSubmit : () => setOpenedModalTolls(false)}
                onClose={() => setOpenedModalTolls(false)}
            />
        </form>
    );
};
